import { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { Button, Container, Typography, Grid, Box } from '@material-ui/core'

import { Email } from '../components/authComponents'

import logo from '../assets/lfc_logo.svg'
import { useValidEmail } from '../hooks/useValidInputs'

import { useAuth } from '../context/authContext'
import { sendForgotPasswordCode } from '../context/dispatchers'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    height: '10vh',
    flexGrow: 1,
  },
  root: {
    height: '100vh',
    flexGrow: 1,
  },
  element: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  text: {
    textAlign: 'center',
  },
}))

const ForgotPassword = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const {
    state: { email: initEmail },
    dispatch,
  } = useAuth()

  const { email, setEmail, emailIsValid } = useValidEmail(initEmail)
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)

  const isInvalid = !emailIsValid || email.length === 0

  const resetPassword = useCallback(
    () => history.push('/resetpassword'),
    [history],
  )

  const sendCodeClicked = useCallback(async () => {
    try {
      await sendForgotPasswordCode(dispatch, email)
      setResetSent(true)
      setTimeout(() => {
        resetPassword()
      }, 5000)
    } catch (err) {
      setError("Unknown user (or don't say this?)")
    }
  }, [dispatch, email, resetPassword])

  const emailSent = (
    <Grid item md>
      <Box mt={1}>
        <Typography
          className={classes.text}
          variant="h5"
        >{`Reset Code Sent to ${email}`}</Typography>
        <p>You will be redirected to reset your password in 5 sec.</p>
      </Box>
      <Box mt={4}>
        <Button onClick={resetPassword} color="primary" variant="contained">
          Reset Password
        </Button>
      </Box>
    </Grid>
  )

  const sendCode = (
    <Grid item md>
      <Box width="80%" m={1}>
        <Email defaultValue={email} emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button
              disabled={isInvalid}
              color="primary"
              variant="contained"
              onClick={sendCodeClicked}
            >
              Send Code
            </Button>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )

  return (
    <Container>
      <Grid container className={classes.root} justify="center" alignItems="center" spacing={3}>
        <Grid item md>
          <Box className={classes.element}>
            <img src={logo} height={240} className={classes.element} alt="LiverpoolFC" />
          </Box>
        </Grid>
        <Grid item md>
          <Typography variant="h5">
            <p>Forgotten password?</p>
          </Typography>
          <Typography variant="h6">
            <p>Enter your email to reset your password</p>
          </Typography>
        </Grid>
        {resetSent ? emailSent : sendCode}
      </Grid>
    </Container>
  )
}
export default ForgotPassword
