import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { Button, TextField, Typography, Grid, Box, Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { useAuth } from '../context/authContext'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { changePassword, setUserAttributes } from '../context/dispatchers'
import { useUserAttributes } from '../hooks/useUserAttributes'
import { Password } from '../components/authComponents'
import { useValidPassword } from '../hooks/useValidInputs'
import { signOut } from '../context/dispatchers'

const Alert = (props: AlertProps): JSX.Element => (
  <MuiAlert elevation={6} variant="filled" {...props} />
)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    textAlign: 'center',
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
}))

const Profile = (): JSX.Element => {
  const history = useHistory()
  const {
    state: { userAttributes },
    dispatch,
  } = useAuth()
  const classes = useStyles()

  const signOutClicked = useCallback(async () => {
    await signOut(dispatch)
    // dispatch({ type: ActionType.SignOut })
    // TODO: show in UI (notification or screen) that user logged out!
    history.push('/')
  }, [dispatch, history])

  const { name, setName, familyName, setFamilyName } = useUserAttributes(userAttributes)

  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
    passwordError: oldPasswordError,
  } = useValidPassword('')

  const {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordIsValid,
    passwordConfirmIsValid,
    passwordError,
  } = useValidPassword('', true)

  const [error, setError] = useState('')
  const [passwordChangeError, setPasswordChangeError] = useState('')
  const areValidAttributes = name.length > 0 && familyName.length > 0

  const isInvalidPassword =
    !oldPasswordIsValid ||
    oldPassword.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const [successSnackOpen, setSuccessSnackOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const saveChanges = useCallback(async () => {
    const attributes = [
      new CognitoUserAttribute({
        Name: 'name',
        Value: name,
      }),
      new CognitoUserAttribute({
        Name: 'family_name',
        Value: familyName,
      }),
    ]

    try {
      await setUserAttributes(dispatch, attributes)
      setSuccessMessage('User profile changed successfully!')
      setSuccessSnackOpen(true)
    } catch (err) {
      setError(err)
    }
  }, [dispatch, familyName, name])


  const changePasswordClicked = useCallback(async () => {
    try {
      await changePassword(dispatch, oldPassword, password)
      setSuccessMessage('Successfully changed password!')
      setSuccessSnackOpen(true)
    } catch (err) {
      setPasswordChangeError(err.message)
    }
  }, [dispatch, oldPassword, password])

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid
            className={classes.root}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <h2>Profile</h2>
            <Box m={2}>
              <Button onClick={signOutClicked} variant="contained" color="primary">
                Sign Out
              </Button>
            </Box>

          </Grid>

          <Grid container item xs={12} spacing={3}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
              <Grid item xs={4}>
                <Box m={2}>
                  <Typography variant="h5">User Attributes</Typography>
                  <pre className={classes.session}>{JSON.stringify(userAttributes, null, 2)}</pre>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box width="80%" m={3}>
                  <Typography variant="h5">Edit Profile</Typography>
                </Box>
                <Box width="80%" m={3}>
                  <TextField
                    fullWidth
                    value={name}
                    variant="outlined"
                    label={'Name'}
                    error={!true}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Box>
                <Box width="80%" m={3}>
                  <TextField
                    fullWidth
                    value={familyName}
                    variant="outlined"
                    label={'Family name'}
                    error={!true}
                    onChange={(e) => setFamilyName(e.target.value)}
                  />
                </Box>
                <Box width="80%" m={3}>
                  <Button
                    disabled={!areValidAttributes}
                    color="primary"
                    variant="contained"
                    onClick={saveChanges}
                  >
                    Save changes
                  </Button>
                </Box>
                {/* Error */}
                <Box width="80%" m={3}>
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box width="80%" m={3}>
                  <Typography variant="h5">Change password</Typography>
                </Box>
                <Box width="80%" m={3}>
                  <Password
                    label="Current Password"
                    passwordIsValid={oldPasswordIsValid}
                    setPassword={setOldPassword}
                  />
                </Box>
                <Box width="80%" m={3}>
                  <Password
                    label="New Password"
                    passwordIsValid={passwordIsValid}
                    setPassword={setPassword}
                  />
                </Box>
                <Box width="80%" m={3}>
                  <Password
                    label="Confirm New Password"
                    passwordIsValid={passwordConfirmIsValid}
                    setPassword={setPasswordConfirm}
                  />
                </Box>

                <Box width="80%" m={3}>
                  <Button
                    disabled={isInvalidPassword}
                    color="primary"
                    variant="contained"
                    onClick={changePasswordClicked}
                  >
                    Change password
                  </Button>
                </Box>
                {/* Error */}
                <Box width="80%" m={3}>
                  <Typography color="error" variant="body2">
                    {oldPasswordError}
                    {passwordError}
                    {passwordChangeError}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={successSnackOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackOpen(false)}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>
    </>
  )
}
export default Profile
