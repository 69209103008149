import axios from 'axios'
import { isProd, SSO_DOMAIN_PROTOCOL, UUID_SSO_APP } from './util'

const baseUrl = `${SSO_DOMAIN_PROTOCOL}/api/`;
const apiClient = axios.create({
  baseURL: baseUrl,
  // baseURL: `${SSO_DOMAIN_PROTOCOL}:${process.env.PORT}/api/`,
  // timeout: isProd ? 3000 : 0,
  headers: {
    'Authorization': `Bearer ${UUID_SSO_APP}`
  }
})
console.info(`Setting API BaseURL to ${baseUrl}`);

export default apiClient
