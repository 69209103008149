import { CognitoUserAttribute, ICognitoUserAttributeData } from 'amazon-cognito-identity-js'
import { differenceWith, concat } from 'ramda'
import fromUnixTime from 'date-fns/fromUnixTime'
import formatDateTime from 'date-fns/format'

export const UUID_SSO_APP = process.env.RAZZLE_UUID_SSO_APP
export const UUID_LFC_COM = process.env.RAZZLE_UUID_LFC_COM
export const UUID_LFC_TVGO = process.env.RAZZLE_UUID_LFC_TVGO
export const UUID_LFC_RETAIL = process.env.RAZZLE_UUID_LFC_RETAIL

export const DATE_TIME_FORMAT = `MMM do, yyyy HH:mm:ss 'GMT' XXX`

export const isProd = process.env.NODE_ENV === 'production'

export const timeout = (ms: number) => new Promise((resolve: any) => setTimeout(resolve, ms))

export const isServer =
  !(typeof window !== 'undefined' && window.document && window.document.createElement)


// e.g. `.liverpoolfc.com`
export const TLD = isServer
  ? `.${process.env.RAZZLE_LFC_TLD}`
  : `.${window.location.hostname.split('.').slice(-2).join('.')}`


export const SSO_DOMAIN_PROTOCOL = `http${isProd ? 's' : ''}://${process.env.RAZZLE_LFC_SSO_DOMAIN}`
console.log(`[INFO] Setting SSO_DOMAIN_PROTOCOL to: ${SSO_DOMAIN_PROTOCOL}`);
export const TLD_PROTOCOL = `http${isProd ? 's' : ''}://${process.env.RAZZLE_LFC_TLD}`
console.log(`[INFO] Setting TLD_PROTOCOL to: ${TLD_PROTOCOL}`);

export const THIRTY_DAYS_MS = 1000 * 60 * 60 * 24 * 30

export const TEST_THIRTY_SECONDS_MS = 1000 * 30
export const TEST_FIVE_MINUTES_MS = 1000 * 60 * 5

export const formatTokenDateTime = (unixtime: number): string =>
  formatDateTime(fromUnixTime(unixtime), DATE_TIME_FORMAT)

export const getAllNewAttributes = (
  allOld: CognitoUserAttribute[],
  justNew: CognitoUserAttribute[],
): CognitoUserAttribute[] => {
  const unchanged = differenceWith((lhs, rhs) => lhs.Name === rhs.Name, allOld, justNew)
  return concat(unchanged, justNew)
}

export const getAttrValue = (name: string, attrs: ICognitoUserAttributeData[]): string => {
  const result = attrs.find((el) => el.Name === name)
  return result ? result.Value : ''
}
