import { Helmet } from 'react-helmet-async'
import { Redirect, Route, Switch } from 'react-router'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { theme } from './style'

import { AuthProvider, AuthIsSignedIn, AuthIsNotSignedIn } from './context/authContext'

import SignIn from './pages/SignIn'
import Register from './pages/Register'
import Profile from './pages/Profile'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import VerifyCode from './pages/VerifyCode'

const PublicRoute = () => (
  <Switch>
    <Route path="/login" component={SignIn} exact/>
    <Route path="/register" component={Register} exact />
    <Route path="/verify" component={VerifyCode} />
    <Route path="/forgotpassword" component={ForgotPassword} exact />
    <Route path="/resetpassword" component={ResetPassword} />
    <Route path="/">
      <Redirect to="/login" />
    </Route>
    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>
)

const MemberRoute = () => (
  <Switch>
    <Route path="/" component={Profile} />
    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>
)

const App = (): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />

    <Helmet>
      <title>{process.env.RAZZLE_NAME}</title>
    </Helmet>

    <AuthProvider>
      <AuthIsSignedIn>
        <MemberRoute />
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <PublicRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>

  </ThemeProvider>
)

export default App
