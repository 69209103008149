import React, { useEffect, useContext, useCallback, useReducer, Dispatch } from 'react'

import { AuthState, AuthStatus } from './stateTypes'
import { Action, ActionType, authReducer } from './reducers'
import { signInSession } from './dispatchers'

export interface AuthContextType {
  state: AuthState
  dispatch: Dispatch<Action>
}

const defaultState: AuthState = {
  sessionInfo: null,
  authStatus: AuthStatus.Loading,
  userAttributes: [],
  email: ''
}

const AuthContext = React.createContext<AuthContextType>({
  state: defaultState,
  dispatch: () => null,
})

const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(authReducer, defaultState)
  const { authStatus } = state

  // TOOD: Handle longer loading state here by returning provider with loading spinner.
  // return <></>

  const getSessionInfo = useCallback(async () => {
    try {
      if (authStatus === AuthStatus.Loading) {
        await signInSession(dispatch)
      }
    } catch (err) {
      dispatch({ type: ActionType.SignOut })
    }
  }, [authStatus])

  useEffect(() => {
    getSessionInfo()
  }, [getSessionInfo])

  return <AuthContext.Provider value={{state, dispatch}}>{children}</AuthContext.Provider>
}

export const AuthIsSignedIn = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { state } = useContext(AuthContext)

  return <>{state.authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { state } = useContext(AuthContext)

  return <>{state.authStatus === AuthStatus.SignedOut ? children : null}</>
}

export { AuthProvider }

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
