import { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import logo from '../assets/lfc_logo.svg'
import { Button, Container, Typography, Grid, Box, Snackbar} from '@material-ui/core'

import { useValidCode, useValidEmail } from '../hooks/useValidInputs'
import { Code, Email } from '../components/authComponents'

import { useAuth } from '../context/authContext'
import { verifyCode, resendConfirmationCode } from '../context/dispatchers'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

const Alert = (props: AlertProps): JSX.Element => (
  <MuiAlert elevation={6} variant="filled" {...props} />
)

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    height: '10vh',
    flexGrow: 1,
  },
  root: {
    height: '100vh',
    flexGrow: 1,
  },
  element: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  link: {
    '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
  },
}))

const VerifyCode = (): JSX.Element => {
  const classes = useStyles()
  const {
    state: { email: initEmail },
    dispatch,
  } = useAuth()

  const { email, setEmail, emailIsValid } = useValidEmail(initEmail)
  const { code, setCode, codeIsValid } = useValidCode('')
  const [error, setError] = useState('')

  const isInvalid = !emailIsValid || email.length === 0 || !codeIsValid || code.length === 0

  const history = useHistory()

  const sendClicked = useCallback(async () => {
    try {
      await verifyCode(dispatch, email, code)
      // TODO: show in UI (notification or screen) that verification succeeded!
      setSnackMessage('Code confirmed successfully. Redirecting to Login page in 5 sec')
      setTimeout(() => {
        history.push('signin')
      }, 5000)
    } catch (err) {
      setError(err.message)
      setSnackMessage(err.message)
    }
    setSnackOpen(true)
  }, [code, dispatch, email, history])

  const resendCode = useCallback(async () => {
    await resendConfirmationCode(dispatch, email)
    setSnackMessage('Confirmation code resent!')
    setSnackOpen(true)
  }, [dispatch, email])

  const [snackOpen, setSnackOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')

  return (
    <Container>
      <Grid container className={classes.root} justify="center" alignItems="center" spacing={3}>
        <Grid item md>
          <Box className={classes.element}>
            <img src={logo} height={240} className={classes.element} alt="LiverpoolFC" />
          </Box>
        </Grid>
        <Grid item md>
          <Typography variant="h5">
            <p>Enter verification Code</p>
          </Typography>
        </Grid>
        <Grid item md>
          {/* Sign In Form */}
          <Box width="80%" m={1}>
            <Email defaultValue={email} emailIsValid={emailIsValid} setEmail={setEmail} />
          </Box>
          <Box width="80%" m={1}>
            <Code codeIsValid={codeIsValid} setCode={setCode} />
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Box onClick={resendCode} mt={2}>
                <Typography className={classes.link} variant="body2">
                  Resend Code
                </Typography>
                <Box mt={2}>
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          {/* Buttons */}
          <Box mt={2}>
            <Grid container direction="row" justify="center">
              <Box m={1}>
                <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
                  Cancel
                </Button>
              </Box>
              <Box m={1}>
                <Button
                  disabled={isInvalid}
                  color="primary"
                  variant="contained"
                  onClick={sendClicked}
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert severity="info">{snackMessage}</Alert>
      </Snackbar>
    </Container>
  )
}
export default VerifyCode
