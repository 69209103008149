import { CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js"
import { AuthState, AuthStatus } from "./stateTypes"
import * as cognito from '../server/cognito'
import { formatTokenDateTime, getAllNewAttributes } from "../lib/util"

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export enum ActionType {
  Load = 'LOAD',
  SignIn = 'SIGN_IN',
  SignInSession = 'SIGN_IN_SESSION',
  SignOut = 'SIGN_OUT',
  SignUp = 'SIGN_UP',
  SignUpVerify = 'SIGN_UP_VERIFY',
  SignUpResendConfirmation = 'SIGN_UP_RESEND_CONFIRMATION',
  SetSessionInfo = 'SET_SESSION_INFO',
  SetUserAttributes = 'SET_USER_ATTRIBUTES',
  ForgotPasswordSendCode = 'FORGOT_PASSWORD_SEND_CODE',
  ForgotPasswordSetNew = 'FORGOT_PASSWORD_SET_NEW',
  ChangePassword = 'CHANGE_PASSWORD'
}

interface Payload {
  [ActionType.Load]: undefined
  [ActionType.SignInSession]: undefined
  [ActionType.SignIn]: undefined
  [ActionType.SignOut]: undefined
  [ActionType.SignUp]: {
    email: string
  }
  [ActionType.SignUpVerify]: undefined
  [ActionType.SignUpResendConfirmation]: undefined
  [ActionType.ForgotPasswordSendCode]: {
    email: string
  }
  [ActionType.ForgotPasswordSetNew]: undefined
  [ActionType.ChangePassword]: undefined
  [ActionType.SetSessionInfo]: {
    session: CognitoUserSession
  }
  [ActionType.SetUserAttributes]: {
    userAttributes: CognitoUserAttribute[]
  }
}

export type Action = ActionMap<Payload>[keyof ActionMap<Payload>]

export const authReducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case ActionType.SignIn:
    case ActionType.SignInSession:
    {
      return {
        ...state,
        authStatus: AuthStatus.SignedIn,
      }
    }
    case ActionType.SignOut: {
      return {
        ...state,
        authStatus: AuthStatus.SignedOut,
      }
    }
    case ActionType.SignUp: {
      return {
        ...state,
        email: action.payload.email,
      }
    }
    case ActionType.SetSessionInfo: {
      const { payload: { session } } = action
      return {
        ...state,
        sessionInfo: {
          idToken: {
            token: session.getIdToken().getJwtToken(),
            expiration: formatTokenDateTime(session.getIdToken().getExpiration()),
            issuedAt: formatTokenDateTime(session.getIdToken().getIssuedAt()),
          },
          accessToken: {
            token: session.getAccessToken().getJwtToken(),
            expiration: formatTokenDateTime(session.getAccessToken().getExpiration()),
            issuedAt: formatTokenDateTime(session.getAccessToken().getIssuedAt()),
          },
          refreshToken: session.getRefreshToken().getToken(),
        },
      }
    }
    case ActionType.SetUserAttributes: {
      const { payload: { userAttributes } } = action
      return {
        ...state,
        userAttributes: getAllNewAttributes(state.userAttributes, userAttributes)
      }
    }
    case ActionType.ForgotPasswordSendCode: {
      return {
        ...state,
        email: action.payload.email
      }
    }
    default: {
      return state
    }
  }
}
