import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { Typography, Grid, Box, Checkbox, Button, Container, FormControlLabel } from '@material-ui/core'

import { Email, Password } from '../components/authComponents'

import logo from '../assets/lfc_logo.svg'
import { useValidEmail, useValidPassword } from '../hooks/useValidInputs'

import { useAuth } from '../context/authContext'
import { signIn } from '../context/dispatchers'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    height: '10vh',
    flexGrow: 1,
  },
  root: {
    height: '100vh',
    flexGrow: 1,
  },
  element: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  link: {
    '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
  },
}))

const SignIn = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const { state: { email: initEmail }, dispatch } = useAuth()

  const { email, setEmail, emailIsValid } = useValidEmail(initEmail)
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState('')

  const isInvalid = !emailIsValid || email.length === 0 || !passwordIsValid || password.length === 0

  const signInEvent = useCallback(async () => {
    try {
      await signIn(dispatch, email, password, rememberMe)
      history.push('/')
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        history.push('/verify')
      } else {
        setError(err.message)
      }
    }
  }, [dispatch, email, history, password])

  const forgotPassword = useCallback(async () => {
    history.push('/forgotpassword')
  }, [history])

  const handleRememberMeChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRememberMe(event.target.checked)
    },
    [setRememberMe],
  )

  return (
    <Container>
      <Grid container className={classes.root} justify="center" alignItems="center" spacing={3}>
        <Grid item md>
          <Box className={classes.element}>
            <img src={logo} height={240} className={classes.element} alt="LiverpoolFC" />
          </Box>
        </Grid>
        <Grid item md>
          <Typography variant="h5">
            <p>
              Log in with your LFC email <br /> and password
            </p>
          </Typography>
        </Grid>
        <Grid item md>
          {/* Sign In Form */}
          <Box width="80%" m={1}>
            <Email defaultValue={email} emailIsValid={emailIsValid} setEmail={setEmail} />{' '}
          </Box>
          <Box width="80%" m={1}>
            <Password
              label="Password"
              passwordIsValid={passwordIsValid}
              setPassword={setPassword}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMeChanged}
                  name="rememberMe"
                  color="primary"
                />
              }
              label="Remember me"
            />
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Box onClick={forgotPassword} mt={2}>
                <Typography className={classes.link} variant="body2">
                  Forgot Password?
                </Typography>
              </Box>
            </Grid>
          </Box>

          {/* Error */}
          <Box mt={2}>
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          </Box>

          {/* Buttons */}
          <Box mt={2}>
            <Grid container direction="row">
              <Box m={1}>
                <Button disabled={isInvalid} color="primary" variant="contained" onClick={signInEvent}>
                  Log In
                </Button>
              </Box>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container direction="row">
              <Box m={1} onClick={() => history.push('/register')}>
                <Typography className={classes.link} variant="body1">
                  Register with a new account
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )

}
export default SignIn
