import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: `Arial, Helvetica Neue, sans-serif`,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
          },
        },
      },
    },
  }),
)
