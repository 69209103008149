import { useState, useEffect } from 'react'
import * as yup from 'yup'

const emailSchema = yup.object().shape({
  email: yup.string().email().required(),
})

// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
const PASSWORD_REGEX = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-+=\\“!@#%&/,><':;|_~`])\S{8,99}$/)
const passwordSchema = yup.object().shape({
  password: yup.string().matches(PASSWORD_REGEX).required(),
})
const PASSWORD_ERROR = `The password must be at least 8 characters long at contain at least one: number, uppercase letter, lowercase letter and a special character`

const codeSchema = yup.object().shape({
  code: yup.string().min(6).required(),
})

export const useValidEmail = (initialValue: string) => {
  const [email, setEmail] = useState(initialValue)
  const [emailIsValid, setEmailIsValid] = useState(true)

  useEffect(() => {

    if (email.length === 0) {
      setEmailIsValid(true)
      return
    }

    const isValid = emailSchema.isValidSync({ email })

    setEmailIsValid(isValid)
  }, [email])

  return { email, setEmail, emailIsValid }
}


export const useValidPassword = (initialValue: string, confirmRequired?: boolean) => {
  const [password, setPassword] = useState(initialValue)
  const [passwordConfirm, setPasswordConfirm] = useState(initialValue)

  const [passwordIsValid, setPasswordIsValid] = useState(true)
  const [passwordConfirmIsValid, setPasswordConfirmIsValid] = useState(true)

  const [passwordError, setPasswordError] = useState('')

  useEffect(() => {
    if (password.length === 0) {
      setPasswordIsValid(true)
      return
    }

    const isValid = passwordSchema.isValidSync({ password })
    setPasswordIsValid(isValid)

    setPasswordError(isValid ? '' : PASSWORD_ERROR)

    if (isValid && confirmRequired && passwordConfirm.length > 0) {
      const isConfirmValid = password === passwordConfirm
      setPasswordConfirmIsValid(isConfirmValid)
      setPasswordError(isConfirmValid ? '' : 'Password do not match')
    }


  }, [confirmRequired, password, passwordConfirm, passwordIsValid])

  return {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordIsValid,
    passwordConfirmIsValid,
    passwordError,
  }
}

export const useValidCode = (initialValue: string) => {
  const [code, setCode] = useState(initialValue)
  const [codeIsValid, setCodeIsValid] = useState(true)

  useEffect(() => {
    if (code.length === 0) {
      setCodeIsValid(true)
      return
    }

    const isValid = codeSchema.isValidSync({ code })

    setCodeIsValid(isValid)
  }, [code])

  return { code, setCode, codeIsValid }
}
