import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Button, Container, TextField, Typography, Grid, Box } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import formatISO from 'date-fns/formatISO'

import logo from '../assets/lfc_logo.svg'
import { Email, Password } from '../components/authComponents'
import { useValidPassword, useValidEmail } from '../hooks/useValidInputs'
import { signUp } from '../context/dispatchers'
import { useAuth } from '../context/authContext'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    height: '10vh',
    flexGrow: 1,
  },
  root: {
    height: '100vh',
    flexGrow: 1,
  },
  element: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  link: {
    '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
  },
}))

const Register = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const { state: { email: initEmail }, dispatch } = useAuth()

  const { email, setEmail, emailIsValid } = useValidEmail(initEmail)
  const {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordIsValid,
    passwordConfirmIsValid,
    passwordError,
  } = useValidPassword('', true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [error, setError] = useState('')
  const [created, setCreated] = useState(false)

  const [birthDate, handleBirthDateChange] = useState<Date | null>(new Date('1990/01/01'))

  const isInvalid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  useEffect(() => {
    setError(passwordError)
  }, [passwordIsValid, passwordError])

  const signUpEvent = useCallback(async () => {
    try {
      if (birthDate === null) return
      const userAttributes = [
        new CognitoUserAttribute({
          Name: 'name',
          Value: firstName,
        }),
        new CognitoUserAttribute({
          Name: 'family_name',
          Value: lastName,
        }),
        new CognitoUserAttribute({
          Name: 'birthdate',
          Value: formatISO(birthDate, { representation: 'date' }),
        }),
      ]
      await signUp(dispatch, email, password, userAttributes)
      setCreated(true)
    } catch (err) {
      setError(err.message)
    }
  }, [birthDate, dispatch, email, firstName, lastName, password])

  const verify = useCallback(() => history.push('/verify'), [history])

  const signUpForm = (
    <Grid item md>
      {/* Sign Up Form */}
      <Box width="80%" m={1}>
        <Email defaultValue={email} emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box width="80%" m={1}>
        <Password
          label="Confirm Password"
          passwordIsValid={passwordConfirmIsValid}
          setPassword={setPasswordConfirm}
        />
      </Box>
      <Box width="80%" m={1}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label={'First name'}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Box>
      <Box width="80%" m={1}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label={'Last name'}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Box>

      <Box width="80%" m={1} p={1}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            required
            label="Birth date"
            value={birthDate}
            onChange={(date) => handleBirthDateChange(date)}
            format="dd/MM/yyyy"
          />
        </MuiPickersUtilsProvider>
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isInvalid} color="primary" variant="contained" onClick={signUpEvent}>
              Sign Up
            </Button>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )

  const accountCreatedForm = (
    <Grid item md>
      <Typography variant="h6">{`Account created for ${email}`}</Typography>
      <Typography variant="h6">{`Verification code sent to your email`}</Typography>

      <Box m={4}>
        <Button onClick={verify} color="primary" variant="contained">
          Verify Code
        </Button>
      </Box>
    </Grid>
  )

  return (
    <Container>
      <Grid container className={classes.root} justify="center" alignItems="center" spacing={3}>
        <Grid item md>
          <Box className={classes.element}>
            <img src={logo} height={240} className={classes.element} alt="LiverpoolFC" />
          </Box>
        </Grid>
        <Grid item md>
          <Typography variant="h5">
            <p>Sign up for a new LFC account</p>
          </Typography>
        </Grid>
        {created ? accountCreatedForm : signUpForm}
      </Grid>
    </Container>
  )
}
export default Register
