import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { useState, useEffect } from 'react'
import { getAttrValue } from '../lib/util'

export const useUserAttributes = (userAttributes: CognitoUserAttribute[]) => {
  const initialName = getAttrValue('name', userAttributes)
  const initialFamilyName = getAttrValue('family_name', userAttributes)

  const [name, setName] = useState(initialName)
  const [familyName, setFamilyName] = useState(initialFamilyName)

  useEffect(() => {
    setName(getAttrValue('name', userAttributes))
    setFamilyName(getAttrValue('family_name', userAttributes))
  }, [userAttributes])

  useEffect(() => {
    setName(name)
    setFamilyName(familyName)
  }, [name, familyName])


  return { name, setName, familyName, setFamilyName }
}
