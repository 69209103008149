import TextField from '@material-ui/core/TextField'

type EmailProps = {
  defaultValue?: string,
  emailIsValid: boolean,
  setEmail: (_: string) => void,
}

export const Email = ({
  defaultValue = '',
  emailIsValid,
  setEmail,
}: EmailProps): JSX.Element => {
  return (
    <TextField
      fullWidth
      required
      variant="outlined"
      label={emailIsValid ? 'Email' : 'Invalid Email'}
      error={!emailIsValid}
      defaultValue={defaultValue}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(evt.target.value)
      }}
    />
  )
}

type PasswordProps = {
  label: string,
  passwordIsValid: boolean,
  setPassword: (_: string) => void
}

export const Password = ({ label, passwordIsValid, setPassword }: PasswordProps): JSX.Element => {
  return (
    <TextField
      fullWidth
      required
      variant="outlined"
      // TODO: provide detailed error msgs for missing/invalid characters
      label={passwordIsValid ? label : 'Invalid password'}
      error={!passwordIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPassword(evt.target.value)
      }}
    />
  )
}

type CodeProps = {
  codeIsValid: boolean,
  setCode: (_: string) => void,
}

export const Code = ({
  codeIsValid,
  setCode,
}: CodeProps): JSX.Element => {
  return (
    <TextField
      fullWidth
      required
      variant="outlined"
      label={codeIsValid ? 'Code' : 'Minimum 6 characters'}
      error={!codeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value)
      }}
    />
  )
}
