import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Code, Email, Password } from '../components/authComponents'

import logo from '../assets/lfc_logo.svg'
import { Button, Container } from '@material-ui/core'
import { useValidEmail, useValidPassword, useValidCode } from '../hooks/useValidInputs'

import { useAuth } from '../context/authContext'
import { confirmForgotNewPassword } from '../context/dispatchers'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    height: '10vh',
    flexGrow: 1,
  },
  root: {
    height: '100vh',
    flexGrow: 1,
  },
  element: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  link: {
    '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
  },
}))

const ResetPassword = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const { state, dispatch } = useAuth()

  const { email, setEmail, emailIsValid } = useValidEmail(state.email)
  const { code, setCode, codeIsValid } = useValidCode('')
  const {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordIsValid,
    passwordConfirmIsValid,
    passwordError,
  } = useValidPassword('', true)
  const [error, setError] = useState('')
  const [resetDone, setResetDone] = useState(false)

  const isInvalid =
    !codeIsValid ||
    code.length === 0 ||
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  useEffect(() => {
    setError(passwordError)
  }, [passwordIsValid, passwordError])

  const goToLogin = useCallback(async () => history.push('/login'), [history])

  const resetPassword = useCallback(async () => {
    try {
      await confirmForgotNewPassword(dispatch, email, code, password)
      setResetDone(true)
      setTimeout(() => {
        goToLogin()
      }, 5000)
    } catch (err) {
      setError(err.message)
    }
  }, [code, dispatch, email, goToLogin, password])

  const resetPasswordForm = (
    <Grid item md>
      <Box width="80%" m={1}>
        <Email defaultValue={email} emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="New Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Confirm New Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => history.goBack()} color="secondary" variant="contained">
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isInvalid} color="primary" variant="contained" onClick={resetPassword}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )

  const passwordResetDone = (
    <Grid item md>
      <Box mt={1}>
        <Typography variant="h5">{`Your new password has been set`}</Typography>
        <p>You will be redirected to log in in 5 sec.</p>
      </Box>
      <Box m={4}>
        <Button onClick={goToLogin} color="primary" variant="contained">
          Log In
        </Button>
      </Box>
    </Grid>
  )

  return (
    <Container>
      <Grid container className={classes.root} justify="center" alignItems="center" spacing={3}>
        <Grid item md>
          <Box className={classes.element}>
            <img src={logo} height={240} className={classes.element} alt="LiverpoolFC" />
          </Box>
        </Grid>
        <Grid item md>
          <Typography variant="h5">
            <p>
              Set a new password
            </p>
          </Typography>
        </Grid>
        {resetDone ? passwordResetDone : resetPasswordForm}
      </Grid>
    </Container>
  )

}
export default ResetPassword
