import { CognitoUserAttribute } from "amazon-cognito-identity-js"

export type IdToken = {
  token: string
  expiration: string
  issuedAt: string
}

export type AccessToken = IdToken

export interface SessionInfo {
  idToken: IdToken
  accessToken: AccessToken
  refreshToken: string
}

export enum AuthStatus {
  Loading = 'LOADING',
  SignedIn = 'SIGNED_IN',
  SignedOut = 'SIGNED_OUT',
}

export interface AuthState {
  sessionInfo: SessionInfo | null
  authStatus: AuthStatus
  userAttributes: CognitoUserAttribute[]

  email: string // pre-login
}
