import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import React from 'react'
import { Action, ActionType } from './reducers'
import apiClient from '../lib/apiClient'

interface AxiosResponse {
  success: boolean
  message?: string
  payload?: CognitoUserAttribute[]
  error?: any
}

export const signInSession = async (dispatch: React.Dispatch<Action>): Promise<void> => {
  try {
    const response = await apiClient.get<AxiosResponse>('/get_user')
    if (!response.data.success && response.data.message) {
      throw new Error(response.data.message)
    }
    dispatch({ type: ActionType.SignInSession })
    const { payload: userAttributes } = response.data
    if (userAttributes) {
      dispatch({ type: ActionType.SetUserAttributes, payload: { userAttributes } })
    }
  } catch (err) {
    dispatch({ type: ActionType.SignOut })
    // TODO: set error notification here.
  }
}

export const signIn = async (
  dispatch: React.Dispatch<Action>,
  email: string,
  password: string,
  rememberMe: boolean,
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/sign_in', {
      email,
      password,
      rememberMe,
    })
    if (!response.data.success && response.data.message) {
      throw new Error(response.data.message)
    }
    dispatch({ type: ActionType.SignIn })
    const { payload: userAttributes } = response.data
    if (userAttributes) {
      dispatch({ type: ActionType.SetUserAttributes, payload: { userAttributes } })
    }
  } catch (err) {
    dispatch({ type: ActionType.SignOut })
    throw err
  }
}

/* ===== Registration ===== */

export const signUp = async (
  dispatch: React.Dispatch<Action>,
  email: string,
  password: string,
  userAttributes: CognitoUserAttribute[],
): Promise<void> => {
  const response = await apiClient.post<AxiosResponse>('/register', {
    email,
    password,
    userAttributes
  })
  if (response.data.success) {
    dispatch({ type: ActionType.SignUp, payload: { email } })
    // Log/Notify.
  } else {
    throw response.data.error
  }
}

export const verifyCode = async (
  dispatch: React.Dispatch<Action>,
  email: string,
  code: string,
): Promise<void> => {
  const response = await apiClient.post<AxiosResponse>('/confirm_registration', {
    email,
    code
  })
  if (response.data.success) {
    dispatch({ type: ActionType.SignUpVerify })
    // Log/Notify.
  } else {
    throw response.data.error
  }
}

export const resendConfirmationCode = async (
  dispatch: React.Dispatch<Action>,
  email: string,
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/resend_confirmation', {
      email,
    })
    if (response.data.success) {
      dispatch({ type: ActionType.SignUpResendConfirmation })
      // Log/Notify.
    }
  } catch (err) {
    // Log/Notify.
  }
}

/* ===== Password reset ===== */

export const sendForgotPasswordCode = async (
  dispatch: React.Dispatch<Action>,
  email: string,
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/forgot', {
      email,
    })
    if (response.data.success) {
      dispatch({ type: ActionType.ForgotPasswordSendCode, payload: { email } })
      // Log/Notify.
    }
  } catch (err) {
    // Log/Notify.
  }
}

export const confirmForgotNewPassword = async (
  dispatch: React.Dispatch<Action>,
  email: string,
  code: string,
  password: string,
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/new_password', {
      email,
      code,
      password
    })
    if (response.data.success) {
      dispatch({ type: ActionType.ForgotPasswordSetNew })
      // Log/Notify.
    }
  } catch (err) {
    // Log/Notify.
  }
}

/* ===== Edit profile ===== */

export const setUserAttributes = async (
  dispatch: React.Dispatch<Action>,
  userAttributes: CognitoUserAttribute[],
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/edit_user', {
      userAttributes
    })
    if (response.data.success) {
      dispatch({ type: ActionType.SetUserAttributes, payload: { userAttributes } })
      // Log/Notify.
    }
  } catch (err) {
    // Log/Notify.
  }
}

export const changePassword = async (
  dispatch: React.Dispatch<Action>,
  oldPassword: string,
  newPassword: string,
): Promise<void> => {
  const response = await apiClient.post<AxiosResponse>('/change_password', {
    oldPassword,
    newPassword
  })
  if (response.data.success) {
    dispatch({ type: ActionType.ChangePassword })
    // Log/Notify.
  } else {
    throw response.data.error
  }
}

export const signOut = async (
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/sign_out')
    if (response.data.success) {
      dispatch({ type: ActionType.SignOut })
      // Log/Notify.
    }
  } catch (err) {
    // Log/Notify.
  }
}
